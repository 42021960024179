var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c("div", { attrs: { id: "body-content-area" } }, [
              _c("div", [
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "block" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.validateBeforeSubmit.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "flex-container" }, [
                            _c("div", { staticClass: "flex-field" }, [
                              _vm._m(1),
                              _c("div", { staticClass: "control" }, [
                                _c("div", { staticClass: "select" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.input.year,
                                          expression: "input.year",
                                        },
                                      ],
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.input,
                                            "year",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(_vm.years, function (opt) {
                                      return _c(
                                        "option",
                                        { key: opt, domProps: { value: opt } },
                                        [_vm._v(_vm._s(opt))]
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "flex-field" }, [
                              _vm._m(2),
                              _c("div", { staticClass: "control" }, [
                                _c("div", { staticClass: "select" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.input.institution,
                                          expression: "input.institution",
                                        },
                                      ],
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.input,
                                              "institution",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          _vm.setInstitution,
                                        ],
                                      },
                                    },
                                    _vm._l(_vm.institutions, function (opt) {
                                      return _c(
                                        "option",
                                        {
                                          key: opt.code,
                                          domProps: { value: opt },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(opt.name) +
                                              " - " +
                                              _vm._s(opt.id)
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "flex-field" }, [
                              _vm._m(3),
                              _c("div", { staticClass: "control" }, [
                                _c("div", { staticClass: "select" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.input.planType,
                                          expression: "input.planType",
                                        },
                                      ],
                                      attrs: { disabled: "" },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.input,
                                              "planType",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          _vm.checkPlanType,
                                        ],
                                      },
                                    },
                                    _vm._l(_vm.planTypes, function (opt) {
                                      return _c(
                                        "option",
                                        {
                                          key: opt.id,
                                          domProps: {
                                            value: _vm.input.planType,
                                            selected:
                                              opt === _vm.input.planType,
                                          },
                                        },
                                        [_vm._v(_vm._s(opt.typeCode))]
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "flex-field" }, [
                              _vm._m(4),
                              _c("div", { staticClass: "field" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.name,
                                      expression: "name",
                                    },
                                  ],
                                  staticStyle: { width: "420px" },
                                  attrs: {
                                    type: "text",
                                    disabled: _vm.disableName,
                                  },
                                  domProps: { value: _vm.name },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.name = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                          _c("div", {
                            staticClass: "block",
                            staticStyle: {
                              padding: "14px 0px 7px 0px",
                              "border-bottom": "solid 1px lightgray",
                            },
                          }),
                          _vm.showForm
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "20px",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "heading",
                                      staticStyle: { "font-size": "20px" },
                                    },
                                    [_vm._v(_vm._s(_vm.name))]
                                  ),
                                  _vm._m(5),
                                  _c("div", { staticClass: "field" }, [
                                    _vm._m(6),
                                    _c(
                                      "div",
                                      { staticClass: "control-center" },
                                      [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.input.legalAddress,
                                              expression: "input.legalAddress",
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          attrs: {
                                            rows: "5",
                                            cols: "50",
                                            name: "legal address",
                                          },
                                          domProps: {
                                            value: _vm.input.legalAddress,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.input,
                                                "legalAddress",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.errors.has(
                                                    "legal address"
                                                  ),
                                                expression:
                                                  "errors.has('legal address')",
                                              },
                                            ],
                                            staticClass: "help is-danger",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first(
                                                  "legal address"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "field" }, [
                                    _vm._m(7),
                                    _c(
                                      "div",
                                      { staticClass: "control-center" },
                                      [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.input.mailingAddress,
                                              expression:
                                                "input.mailingAddress",
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          attrs: {
                                            rows: "5",
                                            cols: "50",
                                            name: "mailing address",
                                          },
                                          domProps: {
                                            value: _vm.input.mailingAddress,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.input,
                                                "mailingAddress",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.errors.has(
                                                    "mailing address"
                                                  ),
                                                expression:
                                                  "errors.has('mailing address')",
                                              },
                                            ],
                                            staticClass: "help is-danger",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first(
                                                  "mailing address"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "field" }, [
                                    _vm._m(8),
                                    _c(
                                      "div",
                                      { staticClass: "control-center" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.input.phone,
                                              expression: "input.phone",
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          attrs: { name: "business phone" },
                                          domProps: { value: _vm.input.phone },
                                          on: {
                                            keydown: _vm.forceFormat,
                                            keyup: _vm.formatPhoneInput,
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.input,
                                                "phone",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.errors.has(
                                                    "business phone"
                                                  ),
                                                expression:
                                                  "errors.has('business phone')",
                                              },
                                            ],
                                            staticClass: "help is-danger",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first(
                                                  "business phone"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "field" }, [
                                    _vm._m(9),
                                    _c(
                                      "div",
                                      { staticClass: "control-center" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.input.fax,
                                              expression: "input.fax",
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          attrs: { name: "fax number" },
                                          domProps: { value: _vm.input.fax },
                                          on: {
                                            keydown: _vm.forceFormat,
                                            keyup: _vm.formatPhoneInput,
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.input,
                                                "fax",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.errors.has("fax number"),
                                                expression:
                                                  "errors.has('fax number')",
                                              },
                                            ],
                                            staticClass: "help is-danger",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first("fax number")
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._m(10),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "table",
                                      staticStyle: { width: "100%" },
                                    },
                                    [
                                      _c("DataTable", {
                                        attrs: {
                                          "header-fields": _vm.contactFields,
                                          tableData: _vm.contactTable || [],
                                          "is-loading": _vm.isLoading,
                                          css: _vm.datatableCss,
                                          "not-found-msg": "No contacts found",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "actions",
                                              fn: function (props) {
                                                return _c("input", {
                                                  staticClass: "btn btn-info",
                                                  attrs: {
                                                    type: "button",
                                                    value: "-",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeRow(
                                                        _vm.contactTable,
                                                        props
                                                      )
                                                    },
                                                  },
                                                })
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2695938382
                                        ),
                                      }),
                                      _c("br"),
                                    ],
                                    1
                                  ),
                                  _vm._m(11),
                                  _c(
                                    "div",
                                    { staticClass: "table" },
                                    [
                                      _c("DataTable", {
                                        attrs: {
                                          "header-fields":
                                            _vm.participantFields,
                                          tableData: _vm.participantTable || [],
                                          "is-loading": _vm.isLoading,
                                          css: _vm.datatableCss,
                                          "not-found-msg":
                                            "No participants found",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "actions",
                                              fn: function (props) {
                                                return _c("input", {
                                                  staticClass: "btn btn-info",
                                                  attrs: {
                                                    type: "button",
                                                    value: "-",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeRow(
                                                        _vm.participantTable,
                                                        props
                                                      )
                                                    },
                                                  },
                                                })
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2329150225
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._m(12),
                                  _c("div", { staticClass: "columns" }, [
                                    _c(
                                      "div",
                                      { staticClass: "column flex-column" },
                                      [
                                        _vm._m(13),
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _vm._m(14),
                                            _c("kendo-datepicker", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              staticStyle: { width: "200px" },
                                              attrs: {
                                                id: "dueDate",
                                                name: "due date",
                                                format: "yyyy-MM-dd",
                                              },
                                              model: {
                                                value: _vm.input.dueDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.input,
                                                    "dueDate",
                                                    $$v
                                                  )
                                                },
                                                expression: "input.dueDate",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.errors.has(
                                                        "due date"
                                                      ),
                                                    expression:
                                                      "errors.has('due date')",
                                                  },
                                                ],
                                                staticClass: "help is-danger",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.errors.first("due date")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _vm._m(15),
                                            _c("kendo-datepicker", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              staticStyle: { width: "200px" },
                                              attrs: {
                                                id: "asOfDate",
                                                name: "info date",
                                                format: "yyyy-MM-dd",
                                              },
                                              model: {
                                                value: _vm.input.asOfDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.input,
                                                    "asOfDate",
                                                    $$v
                                                  )
                                                },
                                                expression: "input.asOfDate",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.errors.has(
                                                        "info date"
                                                      ),
                                                    expression:
                                                      "errors.has('info date')",
                                                  },
                                                ],
                                                staticClass: "help is-danger",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "info date"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._m(16),
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.input.stickyNote,
                                              expression: "input.stickyNote",
                                            },
                                          ],
                                          attrs: { rows: "5", cols: "50" },
                                          domProps: {
                                            value: _vm.input.stickyNote,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.input,
                                                "stickyNote",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "column flex-column" },
                                      [
                                        _vm._m(17),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "invite-client-wrapper",
                                          },
                                          [
                                            _c("InviteClients", {
                                              attrs: {
                                                model: this.invitationInput,
                                                institutionId:
                                                  this.input.institution.id,
                                              },
                                              on: {
                                                invitationsUpdated:
                                                  _vm.onInvitationsUpdated,
                                              },
                                            }),
                                            _vm._l(
                                              _vm.invitationsTable,
                                              function (reminder, i) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: i,
                                                    staticClass: "flex-center",
                                                    staticStyle: {
                                                      "margin-top": "1rem",
                                                      width: "100%",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex-field",
                                                        staticStyle: {
                                                          padding: "0",
                                                          width: "45%",
                                                        },
                                                      },
                                                      [
                                                        _vm._m(18, true),
                                                        _c("kendo-datepicker", {
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            id: "reminderDate",
                                                            format:
                                                              "yyyy-MM-dd",
                                                          },
                                                          model: {
                                                            value:
                                                              reminder.reminderDate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                reminder,
                                                                "reminderDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "reminder.reminderDate",
                                                          },
                                                        }),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                reminder.reminderDate,
                                                              expression:
                                                                "reminder.reminderDate",
                                                            },
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: false,
                                                              expression:
                                                                "false",
                                                            },
                                                            {
                                                              name: "validate",
                                                              rawName:
                                                                "v-validate",
                                                              value: "required",
                                                              expression:
                                                                "'required'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            name:
                                                              "reminder date " +
                                                              i,
                                                          },
                                                          domProps: {
                                                            value:
                                                              reminder.reminderDate,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                reminder,
                                                                "reminderDate",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.errors.has(
                                                                    "reminder date " +
                                                                      i
                                                                  ),
                                                                expression:
                                                                  "errors.has(`reminder date ${i}`)",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "help is-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                "The reminder date field is required"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex-field",
                                                        staticStyle: {
                                                          padding: "0",
                                                          width: "45%",
                                                        },
                                                      },
                                                      [
                                                        _vm._m(19, true),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "select",
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      reminder.emailTemplate,
                                                                    expression:
                                                                      "reminder.emailTemplate",
                                                                  },
                                                                  {
                                                                    name: "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    "email template " +
                                                                    i,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal =
                                                                        Array.prototype.filter
                                                                          .call(
                                                                            $event
                                                                              .target
                                                                              .options,
                                                                            function (
                                                                              o
                                                                            ) {
                                                                              return o.selected
                                                                            }
                                                                          )
                                                                          .map(
                                                                            function (
                                                                              o
                                                                            ) {
                                                                              var val =
                                                                                "_value" in
                                                                                o
                                                                                  ? o._value
                                                                                  : o.value
                                                                              return val
                                                                            }
                                                                          )
                                                                      _vm.$set(
                                                                        reminder,
                                                                        "emailTemplate",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.emailTemplates,
                                                                function (opt) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      key: opt.id,
                                                                      domProps:
                                                                        {
                                                                          value:
                                                                            opt,
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          opt.name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.errors.has(
                                                                        "email template " +
                                                                          i
                                                                      ),
                                                                    expression:
                                                                      "errors.has(`email template ${i}`)",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "help is-danger",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    "The email template field is required"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex-field",
                                                        staticStyle: {
                                                          position: "absolute",
                                                          right: "-2.8rem",
                                                          "margin-top":
                                                            "1.4rem",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "button is-light",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeReminder(
                                                                  reminder
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("-")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "button is-light",
                                                staticStyle: {
                                                  "margin-left": "auto",
                                                  "margin-top": ".5rem",
                                                },
                                                on: { click: _vm.addReminder },
                                              },
                                              [_vm._v("Add reminder")]
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" --> "),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticStyle: { "padding-top": "20px" },
                              attrs: { id: "buttongroup" },
                            },
                            [
                              _c("div", { staticClass: "field is-grouped" }, [
                                _vm._m(20),
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button is-light",
                                      on: {
                                        click: function ($event) {
                                          _vm.resetForm()
                                          _vm.$router.go(-1)
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm.showPlanTypeModal
                        ? _c(
                            "div",
                            {
                              staticClass: "modal is-active",
                              attrs: { id: "plan-type-modal" },
                            },
                            [
                              _c("div", {
                                staticClass: "modal-background",
                                on: {
                                  click: function ($event) {
                                    _vm.showPlanTypeModal = false
                                  },
                                },
                              }),
                              _c("div", { staticClass: "modal-card" }, [
                                _c(
                                  "header",
                                  { staticClass: "modal-card-head" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "modal-card-title" },
                                      [_vm._v("Add New Plan Type")]
                                    ),
                                    _c("a", {
                                      staticClass: "delete",
                                      attrs: { "aria-label": "close" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showPlanTypeModal = false
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "section",
                                  { staticClass: "modal-card-body" },
                                  [
                                    _vm.planTypeExists
                                      ? _c("div", { staticClass: "content" }, [
                                          _c("b", [
                                            _vm._v(
                                              "Plan Type '" +
                                                _vm._s(
                                                  _vm.newPlanType.toUpperCase()
                                                ) +
                                                "' already exists."
                                            ),
                                          ]),
                                          _vm._v(
                                            " Please select it from the list or enter a new Plan Type. "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("div", { staticClass: "field" }, [
                                      _c("div", { staticClass: "control" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.newPlanType,
                                              expression: "newPlanType",
                                            },
                                            {
                                              name: "focus",
                                              rawName: "v-focus",
                                            },
                                          ],
                                          staticClass: "input",
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              "Enter a new Plan Type Code",
                                          },
                                          domProps: { value: _vm.newPlanType },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.newPlanType =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "footer",
                                  { staticClass: "modal-card-foot" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "button is-accent has-text-white",
                                        on: { click: _vm.addPlanType },
                                      },
                                      [_c("span", [_vm._v("Save Plan Type")])]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "button",
                                        on: {
                                          click: function ($event) {
                                            _vm.showPlanTypeModal = false
                                          },
                                        },
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Questionnaire Properties"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label" }, [
      _vm._v(" Year "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label" }, [
      _vm._v(" Institution "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label" }, [
      _vm._v(" Plan Type "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label" }, [
      _vm._v(" Questionnaire Name "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subheading" }, [
      _c("b", [_vm._v("Bank Contact Information")]),
      _c("br"),
      _vm._v(" Please review and correct the information as needed. "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label" }, [
      _vm._v(" Legal Address "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label" }, [
      _vm._v(" Mailing Address "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label" }, [
      _vm._v(" Main Business Phone "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label" }, [
      _vm._v(" Main Fax "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subheading" }, [
      _c("b", [_vm._v("Contact Information")]),
      _c("br"),
      _vm._v(" Please review and adjust entries as needed. "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subheading" }, [
      _c("b", [_vm._v("Plan Participant Basic Information")]),
      _c("br"),
      _vm._v(" Please review and adjust entries as needed. "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Questionnaire Notifications"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block" }, [
      _c("span", { staticClass: "has-text-weight-bold" }, [
        _vm._v("Assign Dates"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Due Date "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Info 'As Of' Date "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "block", staticStyle: { "margin-top": "3rem" } },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Client Portal Notification Message"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block" }, [
      _c("span", { staticClass: "has-text-weight-bold" }, [
        _vm._v("Invite Clients"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Reminder Date/Time "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Email Template "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "control" }, [
      _c(
        "button",
        {
          staticClass: "button is-accent has-text-white",
          attrs: { type: "submit" },
        },
        [_vm._v("Save")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }